import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/app/core/gaurds/auth.gaurd';
import { LayoutComponent } from 'src/app/shared/layout/layout.component';
import { ErrorComponent } from './shared/error/error.component';

const appRoutes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('src/app/features/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'register',
        loadChildren: () => import('src/app/features/register-user/register-user.module').then(m => m.RegisterUserModule)
    },
    {
        path: 'main',
        component: LayoutComponent,
        children: [
            {
                path: 'change-password',
                loadChildren: () => import('src/app/features/change-password/change-password.module').then(m => m.ChangePasswordModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'dashboard',
                loadChildren: () => import('src/app/features/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'branches',
                loadChildren: () => import('src/app/features/branches/branches.module').then(m => m.BranchesModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'employees',
                loadChildren: () => import('src/app/features/employees/employees.module').then(m => m.EmployeesModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'attendance',
                loadChildren: () => import('src/app/features/attendance/attendance.module').then(m => m.AttendanceModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'week-off',
                loadChildren: () => import('src/app/features/weekOff/weekoff.module').then(m => m.WeekOffModule),
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: 'error',
        component: ErrorComponent,
        //loadChildren: () => import('src/app/shared/error/error.module').then(m => m.ErrorModule)
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }