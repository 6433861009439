import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomMenuItem } from '../models/menu-item.model';
import { SessionService } from './session.service';
import { get } from 'lodash';

@Injectable({
    providedIn: 'root',
})
/**
 * menu data service
 */
export class MenuDataService {

    public toggleMenuBar: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    getMenuList(): CustomMenuItem[] {
        let menuList = [
            {Label: 'Home', Icon: 'fa-home', RouterLink: '/main/dashboard', Childs: null, IsChildVisible: false}
        ];
        const getCurrentUser = new SessionService();
        const rolesPermissions = get(getCurrentUser.getItem('currentUser'), 'roles', null);
        if(rolesPermissions.length > 0){
            rolesPermissions[0]['permissions'].filter((item) => {
                if(item.name==='branches_manage'){
                    menuList.push({Label: 'Branches', Icon: 'fa-building', RouterLink: '/main/branches', Childs: null, IsChildVisible: false});
                }
                if(item.name==='users_manage'){
                    menuList.push(
                        {Label: 'Attendance', Icon: 'fa-calendar', RouterLink: '/main/attendance', Childs: null, IsChildVisible: false},
                        {Label: 'Employees', Icon: 'fa-users', RouterLink: '/main/employees', Childs: null, IsChildVisible: false}
                    );
                }
                if(item.name==='weekoffs_manage'){
                     menuList.push({Label: 'Week off', Icon: 'fa-umbrella-beach', RouterLink: '/main/week-off', Childs: null, IsChildVisible: false});
                }
            });
        }
        return menuList;
        // return [
        //     {
        //         Label: 'Departments', Icon: 'fa-sitemap', RouterLink: '/main/departments', Childs: null, IsChildVisible: false
        //     },
        //     {
        //         Label: 'AboutUs', Icon: 'fa-info-circle', RouterLink: '/main/aboutus', Childs: null, IsChildVisible: false
        //     },
        //     {
        //         Label: 'ContactUs', Icon: 'fa-envelope', RouterLink: '/main/contactus', Childs: null, IsChildVisible: false
        //     },
        //     {
        //         Label: 'Error 404', Icon: 'fa-exclamation-triangle', RouterLink: '/error', Childs: null, IsChildVisible: false
        //     },
        //     {
        //         Label: 'Menu Level 1', Icon: 'fa-cart-plus', RouterLink: null, Childs: [
        //             { Label: 'Menu Level 1.1', RouterLink: null, Childs: null, IsChildVisible: false },
        //             {
        //                 Label: 'Menu Level 1.2', RouterLink: null, IsChildVisible: false, Childs: [
        //                     { Label: 'Menu Level 1.2.1', RouterLink: null, Childs: null, IsChildVisible: false },
        //                     { Label: 'Menu Level 1.2.2', RouterLink: null, Childs: null, IsChildVisible: false }
        //                 ]
        //             }
        //         ], IsChildVisible: false
        //     }
        // ];
    }
}